import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { RadioPanel, RadioGroup } from '@entur/form';
import { ExpandableText } from '@entur/expand';
import { VyIcon } from '@entur/icons';
import { Label } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { inputpanel } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageHeader mdxType="PageHeader" />
    <ImportStatement imports="RadioPanel" mdxType="ImportStatement" />
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = React.useState(null)\n  return (\n    <div style={{ width: \'20rem\' }}>\n      <RadioGroup\n        name=\"ticket-type\"\n        label=\"Velg billettype:\"\n        onChange={e => setValue(e.target.value)}\n        value={value}\n      >\n        <div style={{ display: \'grid\', gridGap: \'0.5rem\' }}>\n          <RadioPanel\n            title=\"Standard\"\n            secondaryLabel=\"199,-\"\n            value=\"non-flexi\"\n            size=\"large\"\n          >\n            Ikke fleksibel\n          </RadioPanel>\n          <RadioPanel\n            title=\"Premium\"\n            secondaryLabel=\"299,-\"\n            value=\"flexi\"\n            size=\"large\"\n          >\n            <div>Fleksibel billett</div>\n            {value === \'flexi\' && (\n              <Label as=\"div\">\n                Billetten kan refunderes eller endres ved et senere tidspunkt\n              </Label>\n            )}\n          </RadioPanel>\n        </div>\n      </RadioGroup>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      RadioPanel,
      RadioGroup,
      ExpandableText,
      VyIcon,
      Label,
      Playground,
      ImportStatement,
      PageHeader,
      inputpanel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = React.useState(null);
        return <div style={{
          width: '20rem'
        }}>
        <RadioGroup name="ticket-type" label="Velg billettype:" onChange={e => setValue(e.target.value)} value={value} mdxType="RadioGroup">
          <div style={{
              display: 'grid',
              gridGap: '0.5rem'
            }}>
            <RadioPanel title="Standard" secondaryLabel="199,-" value="non-flexi" size="large" mdxType="RadioPanel">
              Ikke fleksibel
            </RadioPanel>
            <RadioPanel title="Premium" secondaryLabel="299,-" value="flexi" size="large" mdxType="RadioPanel">
              <div>Fleksibel billett</div>
              {value === 'flexi' && <Label as="div" mdxType="Label">
                  Billetten kan refunderes eller endres ved et senere tidspunkt
                </Label>}
            </RadioPanel>
          </div>
        </RadioGroup>
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <h3 {...{
      "id": "retningslinjer"
    }}>{`Retningslinjer`}</h3>
    <p><inlineCode parentName="p">{`RadioPanel`}</inlineCode>{` brukes i tilfeller der du har en liste over alternativer og brukeren kan velge kun ett alternativer.
Den er mest nyttig hvis man ønsker å legge til ekstra informasjon for hvert element, vil fremheve listen eller
ønsker å øke trykkflaten. Ellers kan det hende vanlig `}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/radio-button"
      }}>{`Radio button`}</a>{`
fungerer bedre.`}</p>
    <p>{`Hvis brukerne skal kunne velge flere alternativer fra listen, er det mer passende å bruke
`}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/checkbox-panel"
      }}>{`CheckboxPanel`}</a>{`. Om du ønsker at valget gjenspeiles
eller lagres med en gang bør du heller bruke en `}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjermaelementer/switch"
      }}>{`Switch`}</a>{`.`}</p>
    <p>{`Alternaltivene bør vises i en logisk rekkefølge.`}</p>
    <h3 {...{
      "id": "test-ut-props"
    }}>{`Test ut props`}</h3>
    <p><inlineCode parentName="p">{`RadioPanel`}</inlineCode>{` støtter de samme props-ene som `}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/checkbox-panel"
      }}>{`CheckboxPanel`}</a>{`,
med unntak av `}<inlineCode parentName="p">{`checked`}</inlineCode>{` – dette siden checked-verdien styres gjennom `}<inlineCode parentName="p">{`RadioGroup`}</inlineCode>{`. Du kan teste dem ut interaktivt på
`}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/checkbox-panel#test-ut-props"
      }}>{`siden for CheckboxPanel`}</a>{`.`}</p>
    <h3 {...{
      "id": "radiogroup"
    }}>{`RadioGroup`}</h3>
    <ImportStatement imports="RadioGroup" mdxType="ImportStatement" />
    <p><inlineCode parentName="p">{`RadioGroup`}</inlineCode>{` er en nødvendig wrapper for å bruke `}<inlineCode parentName="p">{`RadioPanel`}</inlineCode>{` og lar deg gruppere flere `}<inlineCode parentName="p">{`RadioPanel`}</inlineCode>{`-er under en overskrift. Du kan spesifisere valgt verdi via `}<inlineCode parentName="p">{`value`}</inlineCode>{`-propen, lytte på endringer med `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` og sette en ledetekst/overskrift med `}<inlineCode parentName="p">{`label`}</inlineCode>{`-propen.`}</p>
    <p><inlineCode parentName="p">{`name`}</inlineCode>{`-propen blir propagert ned til hver av `}<inlineCode parentName="p">{`<Radio />`}</inlineCode>{`-elementene, så du slipper å gjøre dette manuelt.`}</p>
    <h2 {...{
      "id": "komponenter-med-props"
    }}>{`Komponenter med props`}</h2>
    <h3 {...{
      "id": "radiopanel-"
    }}>{`<RadioPanel /`}{`>`}</h3>
    <ImportStatement imports="RadioPanel" mdxType="ImportStatement" />
    <Props of={RadioPanel} mdxType="Props" />
    <h3 {...{
      "id": "radiogroup-"
    }}>{`<RadioGroup /`}{`>`}</h3>
    <ImportStatement imports="RadioGroup" mdxType="ImportStatement" />
    <Props of={RadioGroup} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      